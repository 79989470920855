@import '../node_modules/include-media/dist/include-media';

// Override the default breakpoints map with your custom breakpoints
$breakpoints: (
  small: 320px,
  medium: 768px,
  large: 1024px,
);
body {
  font-family: "Inter", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
font-variation-settings:
  "slnt" 0;
  background-color: #f3f3f3;
  //background-image: url("/images/technologies_bg.png");
  //-size: 100% 110%;
  background-repeat: no-repeat;
  background-position: center;
}

button {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  justify-content: space-between;
letter-spacing: 1px;
line-height: 16.8px;
min-width: 180px;
line-height: 1.4em;
border-radius: 96px;
}

h1, h2, h3 {
  font-weight: 400;
  font-family: 'Inter';
  letter-spacing: -.5px;
}

p {
  letter-spacing: .15px;
    line-height: 1.4em;
    font-weight: 400;
    &:first-child {
      margin-top: 0;
    }
}

.logo {
  max-height: 70px;
}

.account-connect {

  .account-connect-header {
    width: 100%;
    max-width: 960px; /* Adjust this value as needed */
    margin: 0 auto; /* Centers the content */
    padding: 20px; /* Adds some spacing inside the container */
    box-sizing: border-box;
  }

  .account-connect-body {
    width: 100%;
    max-width: 960px; /* Adjust this value as needed */
    margin: 0 auto; /* Centers the content */
    padding: 20px; /* Adds some spacing inside the container */
    box-sizing: border-box;
  }

  .footer-wrapper {
    width: 100%;
    max-width: 960px; /* Adjust this value as needed */
    margin: 0 auto; /* Centers the content */
    padding: 20px 40px; /* Adds some spacing inside the container */
    box-sizing: border-box;
    font-size: 0.8rem;
  }
  .content-area {
    padding: 32px;
    border-radius: 16px;
    border: 1px solid rgba(0, 7, 13, .3);
    background: var(--baseline-white, #fff);
    box-sizing: border-box;
  }
}

.btn-bottom {
  margin-top: 32px;
}

.btn-arrow {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  min-width: clamp(180px, 11.875vw, 228px);
  -webkit-transition: all .3s ease-out !important;
  -moz-transition: all .3s ease-out !important;
  -o-transition: all .3s ease-out !important;
  transition: all .3s ease-out !important;
  padding: 8px 8px 8px 24px;
  text-align: left;
  @include media("<=small") {
    width: 100% !important;
  }
  &.orange {
    background: #eeb111;
    border: 1px solid #eeb111;
    color: #00070c;
  } 
  &:after {
    content: '';
    width: 32px;
    height: 32px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all .3s ease-out !important;
    -moz-transition: all .3s ease-out !important;
    -o-transition: all .3s ease-out !important;
    transition: all .3s ease-out !important;
    //width: clamp(32px, 2.5vw, 48px);
    //height: clamp(32px, 2.5vw, 48px);
  }
  &:before {
    content: '';
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z" fill="%2300070C"/></svg>');
    position: absolute;
    width: 24px;
    height: 24px;
    background-size: contain;
    right: 12px;
    top: 50%;
    transform: translatey(-50%);
    z-index: 10;
    //width: clamp(20px, 1.25vw, 24px);
    //height: clamp(20px, 1.25vw, 24px);
    //right: clamp(14px, 1.04vw, 20px);
  }
}